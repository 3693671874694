define(['app', 'elementViewed'], function(app, elementViewed) {



  var productReviews = function() {
    var self = this;
    self.elementViewed = elementViewed;

    var _config = {
      hasReviews: 'data-has-reviews',
      sortSelector: '[data-js-element=sort]',
      dataSelector: '[data-js-element=data]',
      reviewContentSelector: '[data-js-element=reviewContent]',
      paginationContentSelector: '[data-js-element=paginationContent]',
      loadingSpinnySelector: '[data-js-element=loadingSpinny]',
      yesVoteSelector: '[data-js-element=voteYes]',
      noVoteSelector: '[data-js-element=voteNo]',
      votingReviewId: 'data-js-review-id',
      paginationSelector: '[data-js-element=pagination]',
      pageNumberSelector: '[data-page-number]',
      pageDirectionNextSelector: '[data-direction-next]',
      pageDirectionPreviousSelector: '[data-direction-previous]',
      currentPageSelector: 'data-current-page',
      reviewContentStringTemplatePath: 'components/productReviews/partials/productReviews_reviewContent',
      paginationStringTemplatePath: 'components/productReviews/partials/productReviews_pagination',
      reviewEndpoint: '.review',
      requestParams: {
        sortBy: 'sortBy',
        stringTemplate: 'stringTemplatePath',
        page: 'page'
      },
      lastOperation: null,
      loadingErrorSelector: '[data-js-element=loadingError]',
      loadingErrorIsVisible: false,
      subscribeChannels: {
        productReviews: 'productReviews/newProductReviews',
      }
    };

    var _init = function(element, userDriven) {
      self.element = element;
      self.hasReviews = self.element.getAttribute(self.config.hasReviews) === 'true';

      if (!userDriven) {
        app.subscribe(self.config.subscribeChannels.productReviews, self.getNewReviews);
      }

      if (self.hasReviews) {
        self.sort = self.element.querySelector(self.config.sortSelector);
        self.pagination = self.element.querySelector(self.config.paginationSelector);
        self.paginationContent = self.element.querySelector(self.config.paginationContentSelector);
        self.pageNumbers = self.element.querySelectorAll(self.config.pageNumberSelector);
        self.pageDirectionNext = self.element.querySelectorAll(self.config.pageDirectionNextSelector);
        self.pageDirectionPrevious = self.element.querySelectorAll(self.config.pageDirectionPreviousSelector);
        self.loadingSpinny = self.element.querySelector(self.config.loadingSpinnySelector);
        self.yesVotes = self.element.querySelectorAll(self.config.yesVoteSelector);
        self.noVotes = self.element.querySelectorAll(self.config.noVoteSelector);
        self.loadingError = self.element.querySelector(self.config.loadingErrorSelector);

        self.reviewContent = self.element.querySelector(self.config.reviewContentSelector);
        if (self.reviewContent) {
          self.cachedReviewContent = self.reviewContent.innerHTML;
        }

        var loadingError = self.element.querySelector(self.config.loadingErrorSelector);
        if (loadingError) {
          self.config.loadingErrorShowClass = loadingError.getAttribute('data-loadingError-show');
        }
        var loadingSpin = self.element.querySelector(self.config.loadingSpinnySelector);
        if (loadingSpin) {
          self.config.loadingSpinnyShowClass = loadingSpin.getAttribute('data-show-class');
        }

        self.data = {
          productId: self.element.getAttribute('data-product-id'),
          currentPageIndex: self.element.getAttribute('data-current-page'),
          reviewsPerPage: self.element.getAttribute('data-reviews-per-page'),
          siteSecureURL: self.element.getAttribute('data-url')
        };

        self.bind();
      }

      return self;
    };

    var _bind = function() {
      if (self.sort) {
        self.sort.addEventListener('change', self.sortProductReviews);
      }
      self.elementViewed(self.element, self.trackComponentViewed);
      self.elementViewed(self.pagination, self.trackPaginationViewed);

      for (var i = 0; i < self.yesVotes.length; i++) {
        self.yesVotes[i].addEventListener('click', self.trackYesVote);
        self.noVotes[i].addEventListener('click', self.trackNoVote);
      }
      self.bindPagination();
    };

    var _trackComponentViewed = function() {
      self.triggerTrackingEvent('Viewed', 'productReviews component');
    };

    var _trackPaginationViewed = function() {
      self.triggerTrackingEvent('Viewed', 'productReviews component pagination');
    };

    var _trackYesVote = function() {
      var reviewId = this.getAttribute(self.config.votingReviewId);
      self.triggerTrackingEvent('Voted', 'productReviews component votedYes ' + reviewId);
    };

    var _trackNoVote = function() {
      var reviewId = this.getAttribute(self.config.votingReviewId);
      self.triggerTrackingEvent('Voted', 'productReviews component votedNo ' + reviewId);
    };

    var _triggerTrackingEvent = function(action, label) {
      app.publish('tracking/record', 'Product | Review', action, label);
    };

    var _bindPagination = function() {
      self.pageDirectionNext = self.element.querySelector(self.config.pageDirectionNextSelector);
      if (self.pageDirectionNext) {
        self.pageDirectionNext.addEventListener('click', self.nextPage);
      }

      self.pageDirectionPrevious = self.element.querySelector(self.config.pageDirectionPreviousSelector);
      if (self.pageDirectionPrevious) {
        self.pageDirectionPrevious.addEventListener('click', self.previousPage);
      }

      self.pageNumbers = self.element.querySelectorAll(self.config.pageNumberSelector);

      for (var i = 0, l = self.pageNumbers.length; i < l; i++) {
        self.pageNumbers[i].addEventListener('click', self.changePage);
      }
    };

    var _getNewReviews = function(productId) {
      app.ajax.get({
        url: '/' + productId + '.review',
        success: self.successHandler,
        error: self.errorHandler
      });
    };

    var _refreshCachedReviewContent = function() {
      self.cachedReviewContent = self.reviewContent.innerHTML;
    };

    var _startLoading = function() {
      app.element.addClass(self.config.loadingSpinnyShowClass, self.loadingSpinny);
    };

    var _stopLoading = function() {
      app.element.removeClass(self.config.loadingSpinnyShowClass, self.loadingSpinny);
      self.bind();
    };

    var _changePage = function() {
      var pageNumberRequested = parseInt(this.getAttribute('data-page-number'));
      var pageToGoTo = pageNumberRequested - 1;
      self.triggerTrackingEvent('Clicked', 'page ' + pageNumberRequested);
      self.goToPageNumber(pageToGoTo);
    };

    var _nextPage = function() {
      var pageNumberRequested = self.data.currentPageIndex + 1;
      self.triggerTrackingEvent('Clicked', 'Next page');
      self.goToPageNumber(pageNumberRequested);
    };

    var _previousPage = function() {
      var pageNumberRequested = self.data.currentPageIndex - 1;
      self.triggerTrackingEvent('Clicked', 'Previous page');
      self.goToPageNumber(pageNumberRequested);
    };

    var _goToPageNumber = function(pageNumber) {
      // this function takes the zero-indexed page number for the endpoint,
      // add one for the real page number for tracking
      self.triggerTrackingEvent('Navigated', 'page ' + (pageNumber + 1));

      self.startLoading();

      if (self.loadingErrorIsVisible) {
        self.hideLoadingError();
      }

      var getUrl = self.buildUrlFromConfig(pageNumber);

      self.config.lastOperation = 'pagination';
      self.data.currentPageIndex = pageNumber;

      self.getData(getUrl, self.loadNewReviewData);
    };

    var _buildUrlFromConfig = function(pageNumberOverride, stringTemplateOverride) {
      return self.data.siteSecureURL +
        self.data.productId +
        self.config.reviewEndpoint +
        '?' +
        self.config.requestParams.page +
        '=' +
        (pageNumberOverride ? pageNumberOverride : self.data.currentPageIndex) +
        '&' +
        self.config.requestParams.sortBy +
        '=' +
        self.sort.value +
        '&' +
        self.config.requestParams.stringTemplate +
        '=' +
        (stringTemplateOverride ? stringTemplateOverride
          : self.config.reviewContentStringTemplatePath);
    };

    var _sortProductReviews = function() {
      self.triggerTrackingEvent('Sorted', self.sort.value);

      self.startLoading();

      if (self.loadingErrorIsVisible) {
        self.hideLoadingError();
      }

      self.data.currentPageIndex = 0;

      var getUrl = self.buildUrlFromConfig(0);
      self.getData(getUrl, self.loadNewReviewData);
    };

    var _showLoadingError = function() {
      self.stopLoading();
      app.element.addClass(self.config.loadingErrorShowClass, self.loadingError);
      self.loadingErrorIsVisible = true;
    };

    var _hideLoadingError = function() {
      app.element.removeClass(self.config.loadingErrorShowClass, self.loadingError);
    };

    var _errorLoadingReviewData = function() {
      self.stopLoading();
      self.showLoadingError();
    };

    var _resetLastOperation = function() {
      self.config.lastOperation = null;
    };

    var _refreshPagination = function() {
      var getUrl = self.buildUrlFromConfig(null, self.config.paginationStringTemplatePath);
      self.getData(getUrl, self.loadNewPagination);
    };

    var _getData = function(url, successHandler) {
      app.ajax.get({
        url: url,
        dataType: 'JSON',
        error: self.errorLoadingReviewData,
        success: successHandler
      });
    };

    var _loadNewReviewData = function(response) {
      self.stopLoading();

      if (!response) {
        self.reviewContent.innerHTML = self.cachedReviewContent;
        return;
      }

      self.reviewContent.innerHTML = response;
      self.refreshCachedReviewContent();
      self.refreshPagination();

      if (self.config.lastOperation === 'pagination') {
        self.resetLastOperation();
      }
    };

    var _successHandler = function(response) {
      const parent = self.element.parentNode;
      if (parent) {
        parent.innerHTML = response;
        const newElement = parent.querySelector('[data-component=productReviews]');
        self.init(newElement, true);
      }
    };

    var _errorHandler = function() {
      console.error(
        'ERROR: Could not retrieve new product reviews');
    };

    var _loadNewPagination = function(response) {
      self.paginationContent.innerHTML = response;
      _bindPagination();
    };

    self.config = _config;
    self.init = _init;
    self.bind = _bind;
    self.trackComponentViewed = _trackComponentViewed;
    self.trackPaginationViewed = _trackPaginationViewed;
    self.trackYesVote = _trackYesVote;
    self.trackNoVote = _trackNoVote;
    self.triggerTrackingEvent = _triggerTrackingEvent;
    self.loadNewReviewData = _loadNewReviewData;
    self.errorLoadingReviewData = _errorLoadingReviewData;
    self.startLoading = _startLoading;
    self.stopLoading = _stopLoading;
    self.showLoadingError = _showLoadingError;
    self.hideLoadingError = _hideLoadingError;
    self.refreshCachedReviewContent = _refreshCachedReviewContent;
    self.refreshPagination = _refreshPagination;
    self.loadNewPagination = _loadNewPagination;
    self.resetLastOperation = _resetLastOperation;
    self.bindPagination = _bindPagination;
    self.sortProductReviews = _sortProductReviews;
    self.changePage = _changePage;
    self.buildUrlFromConfig = _buildUrlFromConfig;
    self.getData = _getData;
    self.nextPage = _nextPage;
    self.previousPage = _previousPage;
    self.goToPageNumber = _goToPageNumber;
    self.getNewReviews = _getNewReviews;
    self.successHandler = _successHandler;
    self.errorHandler = _errorHandler;
  };

  return productReviews;
});
